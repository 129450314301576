.Background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #000;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-position: left; */
  filter: blur(1px) brightness(.9);
}