.Timer {
  font-size: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, .2);
  box-shadow: 0 0 9px white;
}

.text {
  text-shadow: 0 0 2px white;
  /* margin: 25px; */
}